import {
  Box,
  Button,
  CloseButton,
  Group,
  Loader,
  Paper,
  TextInput,
  Title,
} from '@mantine/core';
import { TransformedValues, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { IProfile } from '../../../types';
import { updateProfile } from '../../../lib/supabaseClient';
import { IconExclamationCircle } from '@tabler/icons-react';
import { SignatureInput } from '../../../components/SignatureInput';

export const Profile = () => {
  const [loading] = useState(false);
  const profile = useLoaderData() as IProfile;

  const navigate = useNavigate();

  const form = useForm({
    validateInputOnChange: true,

    initialValues: {
      name: profile.name,
      cpf: profile.cpf,
      rg: profile.rg,
      coren: profile.coren,
      signature: profile.signature,
      role: profile.role,
      location: profile.location,
    },

    validate: {
      name: (value) => (value ? null : 'Nome é obrigatório'),
      cpf: (value) =>
        value && /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/.test(value)
          ? null
          : 'CPF inválido',
      // rg: (value) =>
      //   value && /(\d{1,2}\.?)(\d{3}\.?)(\d{3})(-?[0-9Xx]{1})$/.test(value)
      //     ? null
      //     : 'RG inválido',
      //gender: (value) => value === GENDER.FEMALE || value === GENDER.MALE ? null : '';
      // role: (value) => (value ? null : 'Função é obrigatória'),
      location: (value) => (value ? null : 'Cidade é obrigatória'),
      signature: (value) =>
        value && value.text ? null : 'Assinature é obrigatória',
    },

    transformValues: (values): Partial<IProfile> =>
      Object.entries(values).reduce((acc: Partial<IProfile>, [key, val]) => {
        if (form.isDirty(key)) {
          return { ...acc, [key]: val };
        }
        return acc;
      }, {}),
  });

  const sendForm = async (values: TransformedValues<typeof form>) => {
    if (!Object.keys(values).length) {
      return;
    }
    const { error } = await updateProfile(values as IProfile);

    if (error) {
      notifications.show({
        message: 'Erro salvando dados!',
        icon: <IconExclamationCircle size="1.2rem" />,
        color: 'red',
      });
    } else {
      notifications.show({
        message: 'Perfil atualizado com sucesso!',
        color: 'green',
      });
      form.resetDirty();
      form.resetTouched();
    }
  };

  return (
    <Paper radius="md" p="xl" withBorder pos="relative" h="100%">
      {loading ? (
        <Loader size="xl" />
      ) : (
        <Box maw={600} mx="auto">
          <Group position="apart">
            <Title>Editar perfil</Title>
            <CloseButton
              onClick={() => {
                navigate('/app');
              }}
            />
          </Group>
          <form onSubmit={form.onSubmit(sendForm)}>
            <TextInput
              withAsterisk
              label="Nome completo"
              placeholder="Seu nome completo"
              {...form.getInputProps('name')}
            />
            <TextInput
              withAsterisk
              label="CPF"
              placeholder="XXX.XXX.XXX-XX"
              {...form.getInputProps('cpf')}
            />
            <TextInput
              //withAsterisk
              label="Número de identidade"
              placeholder="Seu RG"
              {...form.getInputProps('rg')}
            />
            <TextInput
              label="Coren"
              placeholder="Coren"
              description="Caso não possua coren este campo não é necessário"
              {...form.getInputProps('coren')}
            />
            <TextInput
              label="Local"
              placeholder="Cidade, UF"
              description="Local a ser usado na geração de recibos"
              {...form.getInputProps('location')}
            />
            <SignatureInput {...form.getInputProps('signature')} />
            <Group position="right" mt="md">
              <Button
                disabled={!form.isValid() || !form.isDirty()}
                type="submit"
              >
                Atualizar
              </Button>
            </Group>
          </form>
        </Box>
      )}
    </Paper>
  );
};
