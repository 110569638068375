import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Paper,
  Select,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { TransformedValues, useForm } from "@mantine/form";
import { DatePickerInput } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import {
  IconBuildingHospital,
  IconExclamationCircle,
  IconNumber,
  IconStethoscope,
  IconUser,
} from "@tabler/icons-react";
import { supabase } from "../../../lib/supabaseClient";
import { LinkCloseButton } from "../../../components/LinkButton";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ISurgery } from "../../../types";
import { useState } from "react";

const statusOptions = [
  { label: "Marcada", value: "scheduled" },
  { label: "Pendente", value: "pending" },
  { label: "Paga", value: "paid" },
  { label: "Cancelada", value: "cancelled" },
];

export const EditSurgery = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { editing, surgery } = useLoaderData() as {
    editing: boolean;
    surgery: ISurgery;
  };
  const navigate = useNavigate();

  const form = useForm({
    validateInputOnChange: true,

    initialValues: {
      receipt_id: surgery.receipt_id || "",
      doctor: surgery.doctor || "",
      patient_name: surgery.patient_name || "",
      patient_id: surgery.patient_id || "",
      amount: surgery.amount ? String(surgery.amount) : "",
      date: surgery.date || new Date(Date.now()),
      hospital: surgery.hospital || "",
      title: surgery.title || "",
      status: surgery.status || "pending",
      notes: surgery.notes || "",
    },
    validate: {
      //receipt_id: (value) => !value || !/([0-9])+/.test(value),
      patient_name: (value) => value.trim().length === 0,
      doctor: (value) => value.trim().length === 0,
      amount: (value) =>
        !(value.trim().length === 0 || /^([0-9])+([,.][0-9]{2})?$/.test(value)),
    },

    transformValues: (values): ISurgery =>
      Object.entries(values).reduce((acc: ISurgery, [key, val]) => {
        let value = val;
        if (key == "amount") {
          value = parseFloat((val as string).replace(",", "."));
        }
        return { ...acc, [key]: value || null };
      }, {} as ISurgery),
  });

  const sendForm = async (values: TransformedValues<typeof form>) => {
    if (!Object.keys(values).length) {
      return;
    }

    console.log(surgery.id);

    const { error } = surgery.id
      ? await supabase.from("surgery").update(values).eq("id", surgery.id)
      : await supabase.from("surgery").insert(values);

    if (error) {
      notifications.show({
        message: "Erro salvando dados!",
        icon: <IconExclamationCircle size="1.2rem" />,
        color: "red",
      });
      console.log(error);
    } else {
      // const localProfile = JSON.parse(
      //   window.localStorage.getItem("profile") || "{}"
      // );
      // window.localStorage.setItem(
      //   "profile",
      //   JSON.stringify({ ...localProfile, ...values })
      // );
      return navigate("/app");
    }
  };

  const deleteOrCancel = () => {
    if (!editing) {
      return navigate("/app");
    } else {
      setDeleteModalOpen(true);
    }
  };

  const deleteSurgery = async () => {
    if (!surgery.id) {
      throw new Error(
        "Impossivel deletar cirurgia, identificador não está presente"
      );
    }

    const { error } = await supabase
      .from("surgery")
      .delete()
      .eq("id", surgery.id);

    if (error) {
      notifications.show({
        message: "Erro deletando cirurgia!",
        icon: <IconExclamationCircle size="1.2rem" />,
        color: "red",
      });
    } else {
      return navigate("/app");
    }
  };

  return (
    <Paper radius="md" p="xl" withBorder>
      <form onSubmit={form.onSubmit(sendForm)}>
        <LinkCloseButton pos="absolute" to="/app" />
        <Title order={2} size="h1" weight={600} align="center">
          {editing ? "Editar cirurgia" : "Nova cirurgia"}
        </Title>

        <SimpleGrid
          cols={2}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <TextInput
            label="Número do recibo"
            icon={<IconNumber />}
            placeholder="Número do recibo"
            name="receipt_id"
            {...form.getInputProps("receipt_id")}
          />
          <TextInput
            required
            label="Doutor/a"
            icon={<IconStethoscope />}
            placeholder="Nome do doutor/a"
            name="doctor"
            {...form.getInputProps("doctor")}
          />
          <TextInput
            required
            label="Nome do Paciente"
            icon={<IconUser />}
            placeholder="Nome do paciente"
            name="patient_name"
            {...form.getInputProps("patient_name")}
          />
          <TextInput
            label="CPF do Paciente"
            icon={<IconUser />}
            placeholder="XXX.XXX.XXX-XX"
            name="patient_id"
            {...form.getInputProps("patient_id")}
          />
          {/* {os === 'android' || os === 'ios' ?
              <TextInput
                type='date'
                label="Data"
                icon={<IconCalendar/>}
                placeholder="Data da cirurgia"
                name="date"
                {...form.getInputProps('date')}
              />
              : */}
          <DatePickerInput
            required
            label="Data"
            placeholder="Data da cirurgia"
            valueFormat="DD/MM/YYYY"
            {...form.getInputProps("date")}
          />
          <TextInput
            label="Valor"
            icon={"R$"}
            placeholder="0000,00"
            name="amount"
            {...form.getInputProps("amount")}
          />
          <TextInput
            label="Hospital"
            icon={<IconBuildingHospital />}
            placeholder="Nome do hospital"
            name="hospital"
            {...form.getInputProps("hospital")}
          />
          <TextInput
            label="Cirurgia"
            placeholder="Tipo da cirurgia"
            name="title"
            {...form.getInputProps("title")}
          />

          <Select
            label="Status da cirurgia"
            placeholder="Selecione um status"
            data={statusOptions}
            name="status"
            {...form.getInputProps("status")}
          />
        </SimpleGrid>

        <Textarea
          mt="md"
          label="Notas"
          placeholder="Notas sobre a cirurgia"
          maxRows={10}
          minRows={5}
          autosize
          name="notes"
          {...form.getInputProps("notes")}
        />

        <Group position="center" mt="xl">
          <Button type="submit" size="md">
            {editing ? "Salvar" : "Criar"}
          </Button>

          {editing && (
            <Button
              type="button"
              size="md"
              color="red"
              onClick={deleteOrCancel}
            >
              Deletar
            </Button>
          )}
        </Group>
      </form>
      <Modal
        opened={deleteModalOpen}
        onClose={() => {}}
        withCloseButton={false}
      >
        <Stack>
          <Text align="center" fz="lg">
            Tem certeza que deseja deletar essa cirurgia?
          </Text>
          <Group position="center">
            <Button type="button" size="md" color="red" onClick={deleteSurgery}>
              Deletar
            </Button>
            <Button
              type="button"
              size="md"
              onClick={() => setDeleteModalOpen(false)}
            >
              Não deletar
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Paper>
  );
};
