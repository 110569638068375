import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

export const canShare = async () => {
  return (await Share.canShare()).value || navigator.canShare;
}

export const share = async (title: string, fileName: string, blob: Blob): Promise<boolean> => {
  const shareable = (await Share.canShare()).value;
  console.log('share?');
  if (!shareable) {
    if (navigator.canShare) {
      const shareObj = {
        //text,
        title,
        files: [new File([blob], fileName)]
      };
      if (navigator.canShare(shareObj)) {
        try {
          await navigator.share(shareObj);
          return true;
        } catch (e) {
          console.log('could not share: ', e);
        }
      }
    }
    console.log('device cannot share');
    return false;
  }

  await Filesystem.writeFile({
    path: fileName,
    data: await blobToBase64(blob),
    directory: Directory.Cache,
  });
  const uriResult = await Filesystem.getUri({
    directory: Directory.Cache,
    path: fileName,
  });
  Share.share({
    title,
    //text,
    url: uriResult.uri,
    dialogTitle: "Compartilhar recibo",
  });

  return true;
};


function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}