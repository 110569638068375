import { ReactNode, useMemo } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import { ToWords } from 'to-words';
// import qrcode from 'qrcode-generator';
import { qrcode } from '../lib/qrcode';
import {
  type ISurgery,
  type IProfile,
  FONT_NAMES,
  ValidFontNames,
} from '../types';
import { uuidToSafeBase64 } from '../lib/encoding';

Font.register({ family: 'bebas neue', src: '/fonts/BebasNeue-Regular.woff2' });

// Create styles
const styles = StyleSheet.create({
  body: {
    backgroundColor: '#FFFFFF',
    padding: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#112',
    fontSize: '16px',
    fontFamily: 'Helvetica',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: 0,
    flexGrow: 1,
    fontSize: '28px',
    flexWrap: 'nowrap',
  },

  header_main: {
    fontFamily: 'Times-Roman',
    paddingBottom: 4,
    maxLines: 2,
    maxWidth: 400,
    flex: 1,
    fontSize: 33,
    textDecoration: 'underline',
  },

  header_title: {
    borderBottom: '1px solid black',
  },

  subheader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 20,
    flexGrow: 1,
  },

  recibo_text: {
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  boxed: {
    padding: 0,
    margin: 0,
    fontFamily: 'bebas neue',
    borderTop: '3px solid #222',
    borderBottom: '3px solid #222',
    fontSize: 40,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0.85,
    minWidth: 110,
  },

  boxed_text: {
    top: -5,
  },

  rec_num: {
    padding: 0,
    margin: 0,
    fontSize: 20,
    alignItems: 'center',
  },

  accent: {
    fontFamily: 'Helvetica-Bold',
  },

  main_body: {
    fontSize: 16,
  },

  body_block: {
    marginBottom: 20,
  },

  align_right: {
    textAlign: 'right',
  },

  signature: {
    //height: 100,
    objectFit: 'scale-down',
  },
  signature_underbox: {
    fontSize: 14,
    borderTop: '2px solid #112',
    // width: 205,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    // top: '-20px'
    width: '75%',
  },
  signature_box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  qrcode: {
    position: 'absolute',
    right: 30,
    bottom: 30,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrcode_label: {
    fontSize: 12,
    marginBottom: 2,
  },
  font_clareza: {
    fontFamily: 'Clareza',
    fontSize: 28,
    color: '#3333ff',
    //marginBottom: -15,
  },
  font_carater: {
    fontFamily: 'Caráter',
    fontSize: 30,
    color: '#3333ff',
    marginBottom: -5,
  },
  font_suave: {
    fontFamily: 'Suave',
    fontSize: 29,
    color: '#3333ff',
    marginBottom: -10,
  },
});

const toWords = new ToWords({
  localeCode: 'pt-BR',
  converterOptions: {
    currency: true,
  },
});

const registerFont = (fontName: ValidFontNames | undefined) => {
  console.log(fontName);
  if (!fontName) {
    Font.register({
      family: 'Suave',
      src: `/fonts/${FONT_NAMES.Suave}-Regular.ttf`,
    });
    return;
  }

  Font.register({
    family: fontName,
    src: `/fonts/${FONT_NAMES[fontName]}-Regular.ttf`,
  });
};

const getSignatureComponent = (profile: IProfile) => {
  console.log(profile.signature);
  if (profile.signature?.img) {
    return <Image src={profile.signature.img} style={styles.signature} />;
  }
  if (profile.signature?.text) {
    registerFont(profile.signature.font);
    switch (profile.signature.font) {
      case 'Clareza':
        return (
          <Text style={styles.font_clareza}>{profile.signature.text}</Text>
        );
      case 'Caráter':
        return (
          <Text style={styles.font_carater}>{profile.signature.text}</Text>
        );
      case 'Suave':
      default:
        return <Text style={styles.font_suave}>{profile.signature.text}</Text>;
    }
  }
  return <Text style={styles.font_clareza}>{profile.name}</Text>;
};

const Accent = ({ children }: { children: ReactNode }) => (
  <Text style={styles.accent}>{children}</Text>
);

// Create Document Component
export const ReceiptPDF = ({
  surgery,
  profile,
}: {
  surgery: ISurgery;
  profile: IProfile;
}) => {
  const qrCodeComponent = useMemo(() => {
    const base64 = uuidToSafeBase64(surgery.id);
    console.log(base64);
    const qr = qrcode(3, 'M');
    qr.addData(`https://i-r.cc/${base64}`);
    qr.make();
    // return qr.createDataURL(4);
    return qr.createPdfSvgTag(5, 1);
  }, [surgery.id]);

  const signature = getSignatureComponent(profile);

  return (
    <Document>
      <Page size="A5" orientation="landscape" style={styles.body}>
        <View>
          <View style={styles.header}>
            <View style={styles.header_main}>
              <Text>{profile.name}</Text>
            </View>
            <View style={styles.recibo_text}>
              <View style={styles.rec_num}>
                <Text>Nº {surgery.receipt_id}</Text>
              </View>
              <View style={styles.boxed}>
                <Text style={styles.boxed_text}>RECIBO</Text>
              </View>
            </View>
          </View>
          <View style={styles.subheader}>
            <Text>CPF: {profile.cpf}</Text>
            {profile.rg && (
              <>
                <Text>-</Text>
                <Text>RG: {profile.rg}</Text>
              </>
            )}
            {profile.coren && (
              <>
                <Text>-</Text>
                <Text>COREN: {profile.coren}</Text>
              </>
            )}
          </View>

          <View style={styles.main_body}>
            <Text style={styles.body_block}>
              Recebi de <Accent>{surgery.patient_name}</Accent>, CPF{' '}
              <Accent>{surgery.patient_id}</Accent> a importância de{' '}
              <Accent>
                {surgery.amount &&
                  toWords.convert(Number(surgery.amount)).toLowerCase()}
              </Accent>{' '}
              (R${String(surgery.amount!)}) referente à {profile.role}.
            </Text>
            {surgery.title && (
              <Text>
                Cirurgia realizada: <Accent>{surgery.title}</Accent>.
              </Text>
            )}
            <Text style={styles.body_block}>
              Atendimento prestado no(a) <Accent>{surgery.hospital}</Accent>.
            </Text>
            <Text style={{ ...styles.align_right, marginBottom: 5 }}>
              {profile.location},{' '}
              {new Date(surgery.date || '').toLocaleDateString('pt-BR', {
                dateStyle: 'long',
              })}
            </Text>
          </View>
        </View>
        <View style={styles.signature_box}>
          {signature}
          <View style={styles.signature_underbox}>
            <Text>{profile.name}</Text>
          </View>
        </View>
        <View style={styles.qrcode}>
          <Text style={styles.qrcode_label}>Validação</Text>
          {qrCodeComponent}
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptPDF;
