import { Paper, Text } from "@mantine/core";
import { Container } from "../../components/Container";

export const VerifyEmail = () => (
  <Container>
      <Paper radius="md" p="xl" withBorder>
        <Text size="xl">
          Você vai receber um email de confirmação. Para continuar, siga as instruções no email.
        </Text>
    </Paper>
  </Container>
);