import { ReactNode } from 'react';
import { AppShell, Avatar, Group, Header, LoadingOverlay, Menu, rem } from '@mantine/core';
import { Logo } from './Logo';
import { Link, Outlet, useNavigation } from 'react-router-dom';
import { LinkMenuItem } from './LinkButton';
import { IconLogout, IconSettings, IconUserCircle } from '@tabler/icons-react';
import { useSupabaseClient, useSession } from '@supabase/auth-helpers-react';
import { LandingFooter } from "./LandingFooter";

export const Container = ({ children }: { children?: ReactNode }) => {
  const navigation = useNavigation();
  const supabase = useSupabaseClient();
  const session = useSession();

  const clickLogout = () => {
    window.localStorage.clear();
    supabase.auth.signOut().finally(() => document.location.href="/");
  }

  return (
    <AppShell
      pos="relative"
      padding="md"
      header={
        <Header
          height={60}
          p="xs"
          style={{ fontSize: rem(22), fontWeight: 600 }}
        >
          <Group position="apart" px="md">
            <Link
              style={{ color: "inherit", textDecoration: "inherit" }}
              to={session ? "/app" : "/"}
            >
              <Logo height={40} /> Instrumentar
            </Link>

            {session && (
              <Menu position="bottom-end" withArrow>
                <Menu.Target>
                  <Avatar radius="xl" style={{ cursor: "pointer" }}>
                    <IconSettings size="1.5rem" />
                  </Avatar>
                </Menu.Target>

                <Menu.Dropdown>
                  <LinkMenuItem
                    to="/app/profile"
                    icon={
                      <IconUserCircle
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Perfil
                  </LinkMenuItem>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={clickLogout}
                    icon={
                      <IconLogout style={{ width: rem(14), height: rem(14) }} />
                    }
                  >
                    Desconectar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        </Header>
      }
      // footer={
      //   <Footer height={60} p="md">
      //     Application footer
      //   </Footer>
      // }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      footer={<LandingFooter />}
    >
      <LoadingOverlay
        visible={navigation.state === "loading"}
        overlayBlur={2}
      />
      {children || <Outlet />}
    </AppShell>
  );
};
