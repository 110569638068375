import { Modal, Paper } from '@mantine/core';
import { Container } from '../../components/Container';
import { useDisclosure } from '@mantine/hooks';

export const ValidateReceipt = () => {
  const [opened, { close }] = useDisclosure(true);
  return (
    <Container>
      <Modal opened={opened} onClose={close} title="Authentication" centered>
        {/* Modal content */}
      </Modal>
      <Paper radius="md" p="xl" withBorder></Paper>
    </Container>
  );
};
