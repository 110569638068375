import {
  Box,
  Button,
  Group,
  Loader,
  Paper,
  TextInput,
  AppShell,
  Header,
  rem,
  Checkbox,
} from '@mantine/core';
import { TransformedValues, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProfile, ISignature } from '../../../types';
import { createProfile } from '../../../lib/supabaseClient';
import { useUser } from '@supabase/auth-helpers-react';
import { IconExclamationCircle } from '@tabler/icons-react';
import { Logo } from '../../../components/Logo';
import { SignatureInput } from '../../../components/SignatureInput';

export const Onboarding = () => {
  const [loading] = useState(false);
  const user = useUser();
  const navigate = useNavigate();
  //const profile = useLoaderData() as IProfile | null;

  // DO NOT SUBMIT TOS
  const ignoreFormValues = ['tos'];
  const form = useForm({
    validateInputOnChange: true,

    initialValues: {
      userid: user?.id,
      name: '',
      cpf: '',
      rg: '',
      coren: '',
      //gender: '',
      role: 'instrumentação cirúrgica',
      location: '',
      tos: false,
      signature: {} as ISignature,
    },

    validate: {
      name: (value) => (value ? null : 'Nome é obrigatório'),
      cpf: (value) =>
        value && /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/.test(value)
          ? null
          : 'CPF inválido',
      //rg: (value) => !value || (value && (/(\d{1,2}\.?)(\d{3}\.?)(\d{3})(-?[0-9Xx]{1})$/).test(value)) ? null : 'RG inválido',
      //gender: (value) => value === GENDER.FEMALE || value === GENDER.MALE ? null : '';
      role: (value) => (value ? null : 'Função é obrigatória'),
      location: (value) => (value ? null : 'Cidade é obrigatória'),
      tos: (value) =>
        value
          ? null
          : 'É necessário aceitar os termos de serviço para continuar',
      signature: (value) => (value ? null : 'Assinature é obrigatória'),
    },

    transformValues: (values): IProfile => {
      const profile = Object.entries(values).reduce(
        (acc: IProfile, [key, val]) => {
          if (!ignoreFormValues.includes(key)) {
            return { ...acc, [key]: val || null };
          }
          return acc;
        },
        {} as IProfile
      );

      return profile;
    },
  });

  const sendForm = async (values: TransformedValues<typeof form>) => {
    if (!Object.keys(values).length) {
      return;
    }

    const { error } = await createProfile(values);

    if (error) {
      notifications.show({
        message: 'Erro salvando dados!',
        icon: <IconExclamationCircle size="1.2rem" />,
        color: 'red',
      });
    } else {
      const localProfile = JSON.parse(
        window.localStorage.getItem('profile') || '{}'
      );
      window.localStorage.setItem(
        'profile',
        JSON.stringify({ ...localProfile, ...values })
      );
      return navigate('/app/payment-onboarding');
    }
  };

  return (
    <AppShell
      pos="relative"
      padding="md"
      header={
        <Header
          height={60}
          p="xs"
          style={{ fontSize: rem(22), fontWeight: 600 }}
          pl="xl"
        >
          <Logo height={40} /> Instrumentar
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Paper radius="md" p="xl" withBorder>
        {loading ? (
          <Loader size="xl" />
        ) : (
          <Box maw={600} mx="auto">
            <h1>Crie seu perfil</h1>
            <form onSubmit={form.onSubmit(sendForm)}>
              <TextInput
                label="Email"
                placeholder="Email"
                value={user?.email}
                disabled
              />
              <TextInput
                withAsterisk
                label="Nome"
                placeholder="Seu nome"
                {...form.getInputProps('name')}
              />
              <TextInput
                withAsterisk
                label="CPF"
                placeholder="XXX.XXX.XXX-XX"
                {...form.getInputProps('cpf')}
              />
              <TextInput
                label="Número de identidade"
                placeholder="Seu RG"
                {...form.getInputProps('rg')}
              />
              <TextInput
                label="Coren"
                placeholder="Coren"
                description="Caso não possua coren este campo não é necessário"
                {...form.getInputProps('coren')}
              />
              <TextInput
                withAsterisk
                label="Local"
                placeholder="Cidade, UF"
                description="Local a ser usado na geração de recibos"
                {...form.getInputProps('location')}
              />
              <SignatureInput {...form.getInputProps('signature')} />
              <Group mt="md" position="left">
                <Checkbox
                  {...form.getInputProps('tos')}
                  label="Eu concordo com os termos de privacidade e política de uso do aplicativo."
                />
                {/* <Checkbox
                {...form.getInputProps('contact-agreement')}
                label="Eu aceito receber emails sobre novidades e informações úteis do Instrumentar (nós mandamos emails só em casos importantes, também detestamos quando mandamos )."
              /> */}
              </Group>
              <Group position="right" mt="md">
                <Button
                  disabled={!form.isValid() || !form.isDirty()}
                  type="submit"
                >
                  Continuar
                </Button>
              </Group>
            </form>
          </Box>
        )}
      </Paper>
    </AppShell>
  );
};
