import {
  ActionIcon,
  Button,
  ButtonProps,
  CloseButton,
  CloseButtonProps,
  createStyles,
  ActionIconProps,
  Menu,
  MenuItemProps,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, To, useNavigate } from 'react-router-dom';

interface ILinkButtonProps extends ButtonProps {
  to: To;
  className?: string;
}

const useStyles = createStyles(() => ({
  default: {},
}));

export const LinkButton = ({
  children,
  className,
  ...props
}: ILinkButtonProps) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      className={cx(classes.default, className)}
      {...props}
      component={Link}
    >
      {children}
    </Button>
  );
};

export const LinkCloseButton = ({
  ...props
}: CloseButtonProps & { to: To }) => (
  <CloseButton component={Link} {...props} />
);

export const LinkActionIcon = ({
  children,
  ...props
}: ActionIconProps & { to: To }) => (
  <ActionIcon component={Link} {...props}>
    {children}
  </ActionIcon>
);

export const LinkMenuItem = ({
  children,
  ...props
}: MenuItemProps & { to: To }) => (
  <Menu.Item component={Link} to={props.to} icon={props.icon}>
    {children}
  </Menu.Item>
);

export const BackButton = ({ to }: { to?: string }) => {
  const navigate = useNavigate();
  const dest = to || '..';
  return (
    <ActionIcon
      variant="light"
      color="gray"
      size="xl"
      radius="xl"
      aria-label="Retornar"
    >
      <IconArrowLeft
        onClick={() => {
          navigate(dest);
        }}
      />
    </ActionIcon>
  );
};
