import {
  NativeSelect,
  Text,
  Stack,
  Divider,
  Loader,
  Center,
  TextInput,
} from '@mantine/core';
import { FONT_NAMES, ISignature } from '../types';
import './signature.css';
// import { useFont } from '@react-hooks-library/core';
import { useEffect, useState } from 'react';

const DEFAULT_FONT = 'Clareza';

export const SignatureInput = ({
  value,
  onChange,
  ...props
}: {
  onChange: (signature: ISignature) => Promise<boolean>;
  value?: ISignature | null;
  checked?: boolean | undefined;
  error?: React.ReactNode;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      font: value?.font || DEFAULT_FONT,
      text: e.currentTarget.value,
    });
  };

  const handleFontChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFontLoaded(false);
    onChange({
      ...value,
      font: event.currentTarget.value as keyof typeof FONT_NAMES,
    });
  };

  const fontName = value?.font || DEFAULT_FONT;
  // const { error: fontError, loaded } = useFont(
  //   fontName,
  //   `/fonts/${FONT_NAMES[fontName]}-Regular.ttf`
  // );

  // if (fontError) {
  //   throw new Error('Unable to load font');
  // }

  useEffect(() => {
    console.log('signature-input-' + FONT_NAMES[fontName]);
    const font = new FontFace(
      fontName,
      `url(/fonts/${FONT_NAMES[fontName]}-Regular.ttf)`
    );
    document.fonts.add(font);
    font.load();
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
    // document.fonts.onloadingerror(() => {
    // });
  }, [fontName, setFontLoaded]);

  return (
    <Stack>
      <Divider label="Assinatura" labelPosition="center" mt={'lg'} mb={0} />
      <NativeSelect
        mt={0}
        label="Estilo da assinatura"
        value={fontName}
        onChange={handleFontChange}
        data={Object.keys(FONT_NAMES)}
      />
      <Text c="dimmed" fz="sm">
        Escreva sua assinatura abaixo:
      </Text>
      {!fontLoaded ? (
        <Center h={'8rem'}>
          <Loader />
        </Center>
      ) : (
        <TextInput
          classNames={{ input: 'signature-input' }}
          styles={{ input: { fontFamily: fontName } }}
          value={value?.text}
          onChange={handleChange}
          {...props}
        />
      )}
    </Stack>
  );
};
