import React from 'react';
import LogoImg from '../assets/bordered-nurse.svg';
import type { SpacingValue } from "@mantine/core";

export const Logo = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { size?: SpacingValue }
) => (
  <img
    src={LogoImg}
    style={{ maxHeight: "100%", height: props.size, ...props.style }}
    {...props}
  />
);
