import {
  Group,
  Paper,
  Stack,
  Pagination,
  Text,
  Skeleton,
  Button,
  Menu,
} from "@mantine/core";
import { ISurgery } from "../../../types";
import { Table } from "./table";
import { useEffect, useState } from "react";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { Database } from "../../../types";
import { LinkButton } from "../../../components/LinkButton";
import { useLoaderData } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { CardList } from "./cardList";
import { IconFilter } from "@tabler/icons-react";

const FilterMenu = () => {
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button variant="filled" color="blue" size="sm">
          <IconFilter /> Filtrar
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Status</Menu.Label>
        <Menu.Item>Pendente</Menu.Item>
        <Menu.Item>Marcada</Menu.Item>
        <Menu.Item>Paga</Menu.Item>
        <Menu.Item>Cancelada</Menu.Item>
        <Menu.Divider />

        <Menu.Label>Danger zone</Menu.Label>
        <Menu.Item>Transfer my data</Menu.Item>
        <Menu.Item color="red">Delete my account</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export const Home = () => {
  const count = useLoaderData() as number;
  const supabase = useSupabaseClient<Database>();
  const user = useUser();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [surgeries, setSurgeries] = useState<ISurgery[] | null>(null);

  const bigScreen = useMediaQuery("(min-width: 880px)");
  const PAGE_SIZE = bigScreen ? 20 : 10;
  const pagesTotal = count ? Math.ceil(count / PAGE_SIZE) : 0;

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    (async () => {
      const { data: surgery, error } = await supabase
        .from("surgery")
        .select("*")
        .eq("owner", user.id)
        .order("date", { ascending: false })
        .range(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
      if (error) {
        console.error(error);
      } else {
        setSurgeries(surgery);
        setLoading(false);
      }
    })();
  }, [page, user, supabase, PAGE_SIZE]);

  const renderTable = bigScreen ? (
    <Table data={surgeries} />
  ) : (
    <CardList data={surgeries} />
  );

  const Comp = ({ children }: { children: React.ReactNode }) =>
    bigScreen ? (
      <Paper radius="md" p="xl" withBorder pos="relative">
        {children}
      </Paper>
    ) : (
      <>{children}</>
    );

  return (
    <Stack>
      <Paper radius="md" p="xl" withBorder>
        <Group position="apart">
          <LinkButton to="add-surgery">Adicionar cirurgia</LinkButton>
          {/* <Text>Esse mês: R${total}</Text> */}
          {/* <Button variant="filled" color="blue" size="sm">
            <IconSortAscending /> Ordem
          </Button> */}
          <FilterMenu />
        </Group>
      </Paper>
      <Comp>
        {count === 0 ? (
          <>
            <Text>Você ainda não criou nenhuma cirurgia.</Text>
            <LinkButton to="add-surgery">Adicionar cirurgia</LinkButton>
          </>
        ) : (
          <Stack>
            {loading ? (
              Array(Math.min(PAGE_SIZE, count) + 1)
                .fill(0)
                .map((_, i) => {
                  return <Skeleton radius="xl" height={48} p={24} key={i} />;
                })
            ) : (
              <>
                {pagesTotal && (
                  <Group position="right">
                    <Pagination
                      total={pagesTotal}
                      value={page}
                      onChange={setPage}
                      withControls={false}
                      siblings={2}
                      boundaries={1}
                    />
                  </Group>
                )}

                {renderTable}

                {pagesTotal && (
                  <Group position="right">
                    <Pagination
                      total={pagesTotal}
                      value={page}
                      onChange={setPage}
                      withControls={false}
                      siblings={2}
                      boundaries={1}
                    />
                  </Group>
                )}
              </>
            )}
          </Stack>
        )}
      </Comp>
    </Stack>
  );
};
