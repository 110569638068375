export type Json =
  | string
  | number
  | boolean
  | null
  | { [key: string]: Json | undefined }
  | Json[];

export interface Database {
  public: {
    Tables: {
      profile: {
        Row: {
          coren: string | null;
          cpf: string | null;
          gender: number | null;
          location: string | null;
          name: string;
          rg: string | null;
          role: string | null;
          signature: ISignature | null;
          userid: string;
        };
        Insert: {
          coren?: string | null;
          cpf?: string | null;
          gender?: number | null;
          location?: string | null;
          name: string;
          rg?: string | null;
          role?: string | null;
          signature?: ISignature | null;
          userid?: string;
        };
        Update: {
          coren?: string | null;
          cpf?: string | null;
          gender?: number | null;
          location?: string | null;
          name?: string;
          rg?: string | null;
          role?: string | null;
          signature?: ISignature | null;
          userid?: string;
        };
        Relationships: [];
      };
      subscription: {
        Row: {
          created_at: string;
          end_date: string;
          userid: string;
        };
        Insert: {
          created_at?: string;
          end_date: string;
          userid: string;
        };
        Update: {
          created_at?: string;
          end_date?: string;
          userid?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'subscription_userid_fkey';
            columns: ['userid'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          }
        ];
      };
      surgery: {
        Row: {
          amount: number | null;
          created_at: string;
          date: string | null;
          doctor: string | null;
          hospital: string | null;
          id: string;
          notes: string | null;
          owner: string;
          patient_id: string | null;
          patient_name: string | null;
          receipt_id: number | null;
          status: SurgeryStatus | null;
          title: string | null;
        };
        Insert: {
          amount?: number | null;
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: string;
          notes?: string | null;
          owner?: string;
          patient_id?: string | null;
          patient_name?: string | null;
          receipt_id?: number | null;
          status?: SurgeryStatus | null;
          title?: string | null;
        };
        Update: {
          amount?: number | null;
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: string;
          notes?: string | null;
          owner?: string;
          patient_id?: string | null;
          patient_name?: string | null;
          receipt_id?: number | null;
          status?: SurgeryStatus | null;
          title?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'surgery_owner_fkey';
            columns: ['owner'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          }
        ];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      [_ in never]: never;
    };
    Enums: {
      [_ in never]: never;
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
}

export type Tables<
  PublicTableNameOrOptions extends
    | keyof (Database['public']['Tables'] & Database['public']['Views'])
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof (Database[PublicTableNameOrOptions['schema']]['Tables'] &
        Database[PublicTableNameOrOptions['schema']]['Views'])
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? (Database[PublicTableNameOrOptions['schema']]['Tables'] &
      Database[PublicTableNameOrOptions['schema']]['Views'])[TableName] extends {
      Row: infer R;
    }
    ? R
    : never
  : PublicTableNameOrOptions extends keyof (Database['public']['Tables'] &
      Database['public']['Views'])
  ? (Database['public']['Tables'] &
      Database['public']['Views'])[PublicTableNameOrOptions] extends {
      Row: infer R;
    }
    ? R
    : never
  : never;

export type TablesInsert<
  PublicTableNameOrOptions extends
    | keyof Database['public']['Tables']
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions['schema']]['Tables']
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Insert: infer I;
    }
    ? I
    : never
  : PublicTableNameOrOptions extends keyof Database['public']['Tables']
  ? Database['public']['Tables'][PublicTableNameOrOptions] extends {
      Insert: infer I;
    }
    ? I
    : never
  : never;

export type TablesUpdate<
  PublicTableNameOrOptions extends
    | keyof Database['public']['Tables']
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions['schema']]['Tables']
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Update: infer U;
    }
    ? U
    : never
  : PublicTableNameOrOptions extends keyof Database['public']['Tables']
  ? Database['public']['Tables'][PublicTableNameOrOptions] extends {
      Update: infer U;
    }
    ? U
    : never
  : never;

export type Enums<
  PublicEnumNameOrOptions extends
    | keyof Database['public']['Enums']
    | { schema: keyof Database },
  EnumName extends PublicEnumNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicEnumNameOrOptions['schema']]['Enums']
    : never = never
> = PublicEnumNameOrOptions extends { schema: keyof Database }
  ? Database[PublicEnumNameOrOptions['schema']]['Enums'][EnumName]
  : PublicEnumNameOrOptions extends keyof Database['public']['Enums']
  ? Database['public']['Enums'][PublicEnumNameOrOptions]
  : never;

export type SurgeryStatus = 'scheduled' | 'pending' | 'paid' | 'cancelled';

export type ISurgery = Database['public']['Tables']['surgery']['Row'];

export type IProfile = Database['public']['Tables']['profile']['Row'];

export const FONT_NAMES = {
  Clareza: 'OoohBaby',
  Caráter: 'Licorice',
  Suave: 'Sacramento',
};

export type ValidFontNames = keyof typeof FONT_NAMES;

export type ISignature = {
  img?: string;
  text?: string;
  font?: ValidFontNames;
};