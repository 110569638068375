import {
  Table as MantineTable,
  Anchor,
  Text,
  Group,
  ScrollArea,
  createStyles,
  rem,
  Tooltip,
  Badge
} from '@mantine/core';

import {
  IconNumber,
  IconPencil, IconReceipt2
} from '@tabler/icons-react';

import { ReactNode, useState } from 'react';
import { ISurgery, SurgeryStatus } from '../../../types';
import { LinkActionIcon } from '../../../components/LinkButton';

const badgeVariant = "light";
const statusComponent: {[status in SurgeryStatus]: ReactNode} = {
  paid: <Badge color="green" radius="sm" variant={badgeVariant}>Pago</Badge>,
  pending: <Badge color="red" radius="sm" variant={badgeVariant}>Pendente</Badge>,
  cancelled: <Badge color="grey" radius="sm" variant={badgeVariant}>Cancelado</Badge>,
  scheduled: <Badge radius="sm" variant={badgeVariant}>Cancelado</Badge>,
}

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  }
}));

export const Table = ({ data }: {data: ISurgery[] | null}) => {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const rows = data && data.map((row) => {
    return (
      <tr key={row.id}>
        <td>{row.receipt_id || ""}</td>
        <td>
          <Group spacing="sm">
            {/* <Avatar size={40} src={item.avatar} radius={40} /> */}
            <div>
              <Text fz="sm" fw={500}>
                {row.hospital}
              </Text>
              <Text fz="xs" c="dimmed">
                {row.doctor}
              </Text>
            </div>
          </Group>
        </td>
        <td>
          <Anchor component="button" fz="sm">
            {row.patient_name}
          </Anchor>
        </td>
        <td>
          {row.date ? new Date(row.date).toLocaleDateString() : "sem data"}
        </td>
        <td>{row.amount ? `R$${row.amount}` : " - "}</td>
        <td>{row.status && statusComponent[row.status]}</td>
        <td>
          <Group>
            <Tooltip label="Editar">
              <LinkActionIcon to={`edit-surgery/${row.id}`}>
                <IconPencil stroke={1.5} />
              </LinkActionIcon>
            </Tooltip>
            <Tooltip label="Gerar recibo">
              <LinkActionIcon to={`receipt/${row.id}`}>
                <IconReceipt2 stroke={1.5} />
              </LinkActionIcon>
            </Tooltip>
          </Group>
        </td>
      </tr>
    );
  });
  return (
    <ScrollArea h={'100%'} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
      <MantineTable sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th><IconNumber/></th>
            <th>Hospital/Dr.</th>
            <th>Paciente</th>
            <th>Data</th>
            <th>Valor</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </MantineTable>
    </ScrollArea>
  );
};
