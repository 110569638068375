import { Paper, Loader, createStyles, Button, Group } from '@mantine/core';
import type { IProfile, ISurgery } from '../../../types';
import { useLoaderData } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import { ReceiptPDF } from '../../../components/renderReceipt';
import { IconDownload, IconEye, IconShare } from '@tabler/icons-react';
import { usePDF } from '@react-pdf/renderer';
import { canShare, share } from '../../../lib/share';
import { BackButton } from '../../../components/LinkButton';

const useStyles = createStyles(() => ({
  pdfViewer: {
    border: 'none',
  },
}));

const PDFViewer = lazy(() => import('./LazyPDFViewer'));

export const ViewReceipt = () => {
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [viewing, setViewing] = useState(false);
  const { classes } = useStyles();
  const { surgery, profile } = useLoaderData() as {
    surgery: ISurgery;
    profile: IProfile;
  };

  const document = <ReceiptPDF surgery={surgery} profile={profile} />;
  const [instance] = usePDF({ document });
  console.log(instance);

  useEffect(() => {
    (async () => {
      if (navigator.pdfViewerEnabled) {
        setShowView(true);
      } else {
        setShowDownload(true);
      }

      if (await canShare()) {
        setShowShare(true);
      } else {
        setShowDownload(true);
      }
      setLoading(false);
    })();
  }, []);

  const shareClick = () => {
    if (instance.blob) {
      share(
        `Recibo ${surgery.receipt_id} de ${profile.name}`,
        `recibo-${surgery.receipt_id}.pdf`,
        instance.blob
      );
    }
  };

  const viewingClick = () => {
    setViewing(true);
  };

  const actionComponent = (
    <>
      {showShare && (
        <Button onClick={shareClick}>
          <IconShare /> Compartilhar
        </Button>
      )}
      {showDownload && (
        <Button
          ml={16}
          download={`recibo-${surgery.receipt_id || 'cirurgia'}.pdf`}
          component="a"
          href={instance.url!}
        >
          <IconDownload /> Baixar recibo
        </Button>
      )}
      {showView && (
        <Button onClick={viewingClick} color="green" ml={16} disabled={viewing}>
          <IconEye /> Ver recibo
        </Button>
      )}
    </>
  );

  return (
    <Paper
      radius="md"
      p="xl"
      withBorder
      pos="relative"
      h={viewing ? '100%' : 'auto'}
    >
      <Group>
        <BackButton />
        {loading ? <Loader size="xl" /> : actionComponent}
      </Group>

      {viewing && (
        <Suspense fallback={<Loader size="xl" />}>
          {/* <PDFViewer file={instance.blob} /> */}
          <PDFViewer width="100%" height="100%" className={classes.pdfViewer}>
            {document}
          </PDFViewer>
        </Suspense>
      )}
    </Paper>
  );
};
