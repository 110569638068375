import { createClient } from '@supabase/supabase-js';
import { Database, IProfile } from '../types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

export const getUser = async () => {
  return (await supabase.auth.getSession()).data.session?.user;
};

export const getSurgeriesCount = async () => {
  return (
    await supabase.from('surgery').select('*', { count: 'exact', head: true })
  ).count;
};

export const getSurgery = async (id: string) => {
  const surgeries = (await supabase.from('surgery').select('*').eq('id', id))
    .data;

  if (surgeries && surgeries.length > 0) {
    const surgery = surgeries[0];
    return {
      id: String(surgery.id || ''),
      receipt_id: String(surgery.receipt_id || ''),
      doctor: surgery.doctor || undefined,
      patient_id: surgery.patient_id || undefined,
      patient_name: surgery.patient_name || undefined,
      amount: String(surgery.amount || ''),
      date: surgery.date ? new Date(surgery.date) : undefined,
      hospital: surgery.hospital || undefined,
      title: surgery.title || undefined,
      status: surgery.status || undefined,
      notes: surgery.notes || undefined,
      created_at: new Date(surgery.created_at),
    };
  }
  return null;
};

export const getProfile = async () => {
  const user = await getUser();
  const profiles = (
    await supabase.from('profile').select('*').eq('userid', user?.id)
  ).data;
  return profiles ? profiles[0] : null;
  // if (!window.localStorage.getItem('profile')) {
  //   const user = await getUser();
  //   const profiles = (
  //     await supabase.from('profile').select('*').eq('userid', user?.id)
  //   ).data;
  //   console.log(profiles);
  //   if (profiles && profiles.length) {
  //     window.localStorage.setItem('profile', JSON.stringify(profiles[0]));
  //   } else {
  //     return null;
  //   }
  // }

  // return JSON.parse(window.localStorage.getItem('profile')!);
};

export const updateProfile = async (profile: IProfile) => {
  const user = await getUser();
  if (!user) {
    throw new Error('Erro encontrando usuário.');
  }
  const { error } = await supabase
    .from('profile')
    .update(profile)
    .eq('userid', user.id);

  // window.localStorage.setItem('profile', JSON.stringify(profile));
  return { error, profile };
};

export const createProfile = async (profile: IProfile) => {
  const user = await getUser();
  if (!user) {
    throw new Error('Erro encontrando usuário.');
  }
  const { error } = await supabase.from('profile').insert(profile);

  // window.localStorage.setItem('profile', JSON.stringify(profile));
  return { error, profile };
};