import { Container, Group, Anchor, createStyles, rem } from "@mantine/core";
import { Logo } from "./Logo";

const links = [
  { link: "mailto:contato@instrumentar.com.br", label: "Contato" },
  { link: "/priv-br.html", label: "Privacidade" },
  { link: "/t&c.html", label: "Termos de Serviço" },
];

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `${rem("1px")} solid light-dark(${theme.colors.gray[2]}, ${
      theme.colors.dark[2]
    })`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function LandingFooter() {
  const { classes } = useStyles();
  const items = links.map((link) => (
    <Anchor<"a">
      c="dimmed"
      key={link.label}
      href={link.link}
      size="sm"
      target="_blank"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Logo size={46} />
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
}
