import {
  Title,
  Text,
  Container,
  createStyles,
  rem,
  Stack,
  Group,
  Paper,
  Flex,
  ThemeIcon,
  AppShell,
  Header,
  MediaQuery,
  Grid,
  Box,
} from "@mantine/core";
import { Logo } from "../../components/Logo";
import { LinkButton } from "../../components/LinkButton";
import nursePic from "../../assets/transparente.png";
import mockupImg from "../../assets/mockup.png";
import { LandingFooter } from "../../components/LandingFooter";
import {
  IconAlarm,
  IconCoins,
  IconListDetails,
  IconReceipt,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  topNav: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },

  logo: {
    width: 60,
    paddingBottom: 10,
  },

  title: {
    fontWeight: 800,
    fontSize: "9vw",
    letterSpacing: rem(-1),
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: "center",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: "11vw",
    },
    [theme.fn.largerThan("md")]: {
      fontSize: "60px",
      letterSpacing: rem(-1),
    },
  },

  hero: {
    background: "linear-gradient(347.84deg, #BFC8EB -6.91%, #8D9FE0 92.5%)",
    padding: "20px",
    paddingTop: 40,
    paddingBottom: 170,
    transform: "skewY(2deg)",
    marginTop: -25,
    width: "100%",
    [theme.fn.largerThan("md")]: {
      paddingTop: 40,
      paddingBottom: 0,
      overflow: "hidden",
    },
  },

  heroText: {
    color: "#2D253A",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "1.2em",
    fontSize: "13vw",
    [theme.fn.largerThan("md")]: {
      fontSize: "4em",
    },
    [theme.fn.largerThan("xl")]: {
      fontSize: "5em",
    },
  },

  unskew: {
    transform: "skewY(-2deg)",
  },

  description: {
    textAlign: "left",
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    gap: theme.spacing.md,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    height: rem(42),
    fontSize: theme.fontSizes.md,
  },

  secondaryControl: {
    color: theme.white,
    backgroundColor: "rgba(255, 255, 255, .4)",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, .45) !important",
    },
  },

  nursePic: {
    maxWidth: "70%",
    zIndex: 99,
  },

  nursePicText: {
    transform: "translate(0px, 0px) skew(0deg, 4deg)",
    fontWeight: 700,
    fontSize: "2.2em",
    color: "#fff",
    marginTop: "-2em",
    textShadow: "1px 1px 1px rgba(0,0,0,0.2)",
    lineHeight: "2.2em",
  },

  nursePicBox: {
    width: "100%",
    textAlign: "center",
    marginTop: "-130px",
    marginBottom: "40px",
    zIndex: 99,
    transform: "skewY(0)",

    ":before": {
      content: '""',
      display: "block",
      position: "absolute",
      transform: "translate(0px, 0px) skew(0deg, -4deg)",
      background: "rgb(209,132,128)",
      height: "5em",
      width: "90%",
      zIndex: -1,
      bottom: 110,
      marginLeft: "10%",
    },
  },
  nursePicTextBox: {
    transform: "translate(0px, 0px) skew(0deg, -4deg)",
    background: "rgb(145,216,212)",
    height: "5em",
    position: "relative",
    lineHeight: "5em",
  },
}));

const ListPaper = ({ children }: React.PropsWithChildren) => (
  <Grid.Col md={6} lg={5}>
    <Paper shadow="xs" p="md" h="100%">
      <Flex
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="nowrap"
      >
        {children}
      </Flex>
    </Paper>
  </Grid.Col>
);

export const LoggedOut = () => {
  const { classes } = useStyles();

  return (
    <AppShell
      padding={0}
      header={
        <Header height={60} p="xs">
          <Flex justify={"space-between"} align={"center"}>
            <Group>
              <Logo style={{ height: "2.6rem" }} />
              <Text weight={"bold"} size={"lg"}>
                Instrumentar
              </Text>
            </Group>
            <Group position="right">
              <LinkButton variant="white" to="/login?t=login">
                Entrar
              </LinkButton>
            </Group>
          </Flex>
        </Header>
      }
      footer={<LandingFooter />}
    >
      <div className={classes.hero}>
        <Container className={classes.unskew}>
          <div className={classes.topNav}>
            <Logo className={classes.logo} />
            <Title className={classes.title}>Instrumentar</Title>
          </div>
          <Flex gap="md" justify="center" align="start" direction="row">
            <Text size="xl" className={classes.heroText}>
              O aplicativo do Instrumentador Cirúrgico.
            </Text>
            <MediaQuery smallerThan="md" styles={{ display: "none" }}>
              <img
                style={{ marginBottom: -30 }}
                src={mockupImg}
                alt="telefone com exemplo do aplicativo"
              />
            </MediaQuery>
          </Flex>
        </Container>
      </div>
      <Container>
        <Grid gutter={"xl"} align="center">
          <Grid.Col md={6} lg={6}>
            <div className={classes.nursePicBox}>
              <img
                src={nursePic}
                alt="Pra que serve?"
                className={classes.nursePic}
              />
              <Box className={classes.nursePicTextBox} mt={"sm"} mx={"xl"}>
                <Text className={classes.nursePicText}>Como me ajuda?</Text>
              </Box>
            </div>
          </Grid.Col>
          <Grid.Col md={6} lg={6}>
            <Stack>
              <Text size="xl" weight={700} className={classes.description}>
                O seu trabalho é instrumentar ou é cuidar de papelada?
              </Text>
              <Text size="lg" className={classes.description}>
                Você é um profissional da saúde cansado de lidar com papelada,
                rastreando cirurgias e gerenciando suas finanças? Diga adeus ao
                incômodo e dê as boas-vindas ao Instrumentar - a solução
                completa projetada para simplificar sua vida profissional.
              </Text>
            </Stack>
          </Grid.Col>
        </Grid>
        <Grid grow gutter={"lg"} align="stretch" justify="stretch">
          <ListPaper>
            <Stack>
              <Group>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconListDetails size={"1.8em"} />
                </ThemeIcon>
                <Text weight={"bold"}>Acompanhe todas suas cirurgias</Text>
              </Group>
              Diga olá ao trabalho mais organizado e sem estresse. Registre
              todas as cirurgias realizadas em um só lugar. Siga quando fez cada
              cirurgia, busque por cirugião, hospital, pagamentos pendentes e
              muito mais.
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Group>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconReceipt size={"2em"} />
                </ThemeIcon>
                <Text weight={"bold"}>Faturamento sem Esforço</Text>
              </Group>
              <Text>
                Diga adeus às horas gastas organizando papelada e deixe nosso
                aplicativo lidar com o processo de faturamento para você. Gere
                faturas para seus serviços de forma rápida e precisa.
              </Text>
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Flex gap={"md"}>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconCoins size={"2em"} />
                </ThemeIcon>
                <Text weight={"bold"}>
                  Gere relatórios automaticamente para seu contador
                </Text>
              </Flex>
              <Text>
                Acabaram-se os dias de correria para listar todas as suas
                cirurgias na hora de declarar o imposto. O Instrumentar
                simplifica o processo, facilitando o envio de suas informações
                financeiras para seu contador. Passe menos tempo com papelada e
                mais tempo fazendo o que você ama - instrumentando!
              </Text>
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Flex gap={"md"}>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconAlarm size={"2em"} />
                </ThemeIcon>
                <Text weight={"bold"}>
                  Lembretes de cirurgias com pagamento atrasado
                </Text>
              </Flex>
              <Text>
                O cirurgião esqueceu de te pagar? O plano esta demorando mais do
                que o normal pra te pagar? Não fique sem receber e se livre da
                carga mental de se lembrar quais cirurgias foram pagas. Receba
                Lembretes quando cirurgias não foram pagas quando deveriam!
              </Text>
            </Stack>
          </ListPaper>
        </Grid>

        <Stack>
          <Text
            mt={"xl"}
            size="lg"
            className={classes.description}
            weight={700}
          >
            Mais funções no futuro próximo
          </Text>
          <Text size="lg" className={classes.description}>
            Nós estamos sempre trabalhando duro para melhorar o aplicativo e
            facilitar ainda mais a sua vida!
          </Text>
          <Text size="lg" className={classes.description}>
            Em breve o aplicativo vai contar com ainda mais funções, como Pix
            direto no aplicativo, calendário de disponibilidades e muito mais!
          </Text>
          <Text size="lg" className={classes.description} weight={"bold"}>
            Junte-se ao Nosso Beta Gratuitamente
          </Text>
          <Text size={"lg"}>
            Por tempo limitado, o Instrumentar está disponível gratuitamente
            durante nossa fase de teste beta. Aproveite todos os recursos do
            aplicativo sem nenhum custo. Inscreva-se agora e experimente o
            futuro da instrumentação cirúrica!
          </Text>
        </Stack>
        <Group position="center" p={"xl"}>
          <LinkButton size="xl" to="/login?t=register">
            Criar conta
          </LinkButton>
        </Group>
      </Container>
    </AppShell>
  );
};
