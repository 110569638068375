import { StrictMode } from 'react'
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { MantineProvider} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import {LoggedOut} from './pages/loggedOut';
import {Login} from './pages/login';
import { getSurgeriesCount, getSurgery, supabase, getProfile } from './lib/supabaseClient';
import { VerifyEmail } from './pages/verifyEmail';
import { Home } from './pages/app/home';
import { EditSurgery } from './pages/app/editSurgery';
import { Container } from './components/Container';
import { ViewReceipt } from './pages/app/viewReceipt';
import { Profile } from './pages/app/profile';
import { Onboarding } from './pages/app/onboarding';
import { PaymentOnboarding } from './pages/app/onboarding/payment';
import { ValidateReceipt } from './pages/validateReceipt';

const router = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
    element: <LoggedOut />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/app/onboarding",
    element: <Onboarding />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        return redirect("/login");
      }
      const profile = await getProfile();
      return profile || null;
    },
  },
  {
    path: "/app",
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        return redirect("/login");
      }
      if (!(await getProfile())) {
        return redirect("/app/onboarding");
      }

      return session;
    },
    element: <Container />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: async () => {
          return getSurgeriesCount();
        },
      },
      {
        path: "add-surgery",
        element: <EditSurgery />,
        loader: () => ({ editing: false, surgery: {} }),
      },
      {
        path: "edit-surgery/:id",
        element: <EditSurgery />,
        loader: async ({ params }) => {
          if (!params.id) {
            return redirect("/app");
          }
          const surgery = await getSurgery(params.id);

          return {
            editing: true,
            surgery,
          };
        },
      },
      {
        path: "receipt/:id",
        element: <ViewReceipt />,
        loader: async ({ params }) => {
          if (!params.id) {
            return redirect("/app");
          }
          const surgery = await getSurgery(params.id);
          const profile = await getProfile();
          if (surgery && profile) {
            return {
              surgery,
              profile,
            };
          }
          return null;
        },
      },
      {
        path: "profile",
        element: <Profile />,
        loader: async () => {
          const profile = await getProfile();
          return profile || null;
        },
      },
      {
        path: "payment-onboarding",
        element: <PaymentOnboarding />,
      },
      {
        path: "r/:id",
        element: <ValidateReceipt />,
      },
    ],
  },
]);

const theme = {
  fontFamily: 'Roboto, Inter, Avenir, Helvetica, system-ui, Arial, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Greycliff CF, sans-serif' },
};

function App() {
  return (
    <StrictMode>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <SessionContextProvider supabaseClient={supabase}>
          <Notifications />
          <RouterProvider router={router} />
        </SessionContextProvider>
      </MantineProvider>
    </StrictMode>
  );
}

export default App
