import { Box, Group, Paper, Text, AppShell, Header, rem, Title, Alert } from '@mantine/core';
import { IconCoinOff, IconInfoCircle } from '@tabler/icons-react';
import { Logo } from '../../../components/Logo';
import { LinkButton } from '../../../components/LinkButton';

export const PaymentOnboarding = () => {
  return (
    <AppShell
      pos="relative"
      padding="md"
      header={
        <Header
          height={60}
          p="xs"
          style={{ fontSize: rem(22), fontWeight: 600 }}
          pl="xl"
        >
          <Logo height={40} /> Instrumentar
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          paddingTop: theme.spacing.md,
        },
      })}
    >
      <Paper radius="md" p="xl" withBorder>
        <Box maw={600} mx="auto">
          <Title mb={'xl'} order={1}>
            Plano da Instrumentar
          </Title>
          <Title mb={'lg'} order={3}>
            <IconCoinOff /> Acesso temporáriamente gratuito a todas as funções
            do Instrumentar.
          </Title>
          <Text size="lg">
            O Instrumentar esta atualmente em etapa de teste aberto para o
            público.
          </Text>
          <Text mb={'md'} size="lg">
            Durante este periodo, o acesso ao aplicativo e suas funcionalidades
            é gratuito.
          </Text>
          <Text mb={'md'} size="xl" fw={700}>
            Uma vez o periodo de teste terminado, nos vamos contactar nossos
            usuários com mais informações sobre nossos planos e preços.
          </Text>
          <Text mb={'md'} size="lg">
            Usuários que terminarem seu cadastro e usarem o aplicativo durante o
            perido de teste terão promoções especiais uma vez os testes
            terminados. Aproveite!
          </Text>

          <Alert
            variant="light"
            color="blue"
            radius="md"
            title="Modo de teste"
            icon={<IconInfoCircle />}
          >
            Como estamos em modo de teste, você é uma das primeiras pessoas a
            usar nossos app. Isso permite a você usar o aplicativo antes de todo
            mundo, e de graça! Durante esse periodo, não podemos garantir o
            funcionamento perfeito do aplicativo, visto que ainda o estamos
            aprimorando e corrigindo erros. O aplicativo também não está
            completo, mas você vai ver novas funcionalidades muito úteis para
            você e seu trabalho aparecendo todo dia!
          </Alert>
          <Group position="right" mt="md">
            <LinkButton to="/app" type="submit">
              Finalizar inscrição
            </LinkButton>
          </Group>
        </Box>
      </Paper>
    </AppShell>
  );
};
