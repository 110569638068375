import { parse as uuidParse } from 'uuid';

const btoaReplace = (match: string): string => {
  switch (match) {
    case '+':
      return '.';
    case '/':
      return '_';
    case '=':
      return '-';
    default:
      return match;
  }
};
const atobReplace = (match: string): string => {
  switch (match) {
    case '.':
      return '+';
    case '_':
      return '/';
    case '-':
      return '=';
    default:
      return match;
  }
};

export const uuidToSafeBase64 = (uuid: string) =>
  btoa(String.fromCharCode(...Array.from(uuidParse(uuid))))
    .replace(/[+/=]{1}/g, btoaReplace)
    .slice(0, -2);

export const safeBase64toUuid = (base64: string): Uint8Array =>
  Uint8Array.from(atob(atobReplace(`${base64}--`)), (c) => c.charCodeAt(0));
